'use client';

import clsx from 'clsx';

import {
  ComponentProps,
  FC,
  ReactNode,
  cloneElement,
  useCallback,
  useRef,
  useState,
} from 'react';

import styles from './media-banner.module.scss';

export interface MediaBannerProps extends ComponentProps<'div'> {
  image?: ReactNode;
  video?: ReactNode;
  videoOptions?: {
    showChildrenOnComplete?: boolean;
    fadeContentIn?: boolean;
  };
  fade_children?: boolean;
  icon?: ReactNode;
  variant: 'curved' | 'flat';
  children: ReactNode;
  contentWidth?: 'full' | 'narrow';
}

export const MediaBanner: FC<MediaBannerProps> = ({
  image,
  video,
  videoOptions,
  icon,
  variant,
  children,
  contentWidth,
  className,
  fade_children,
  ...props
}) => {
  const currentMediaBanner = useRef<HTMLDivElement>(null);

  const [videoComplete, setVideoComplete] = useState(false);
  const [videoError, setVideoError] = useState(false);

  const scrollTo = useCallback(() => {
    const current = currentMediaBanner?.current;
    const next = current?.nextElementSibling as HTMLDivElement;
    if (next) {
      next.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentMediaBanner]);

  const handleVideoEnd = () => {
    setVideoComplete(true);
  };

  const handleVideoLoad = () => {
    setVideoComplete(false);
  };

  const handleVideoError = () => {
    setVideoError(true);
    setVideoComplete(true);
  };

  return (
    <div
      data-testid='media-banner'
      ref={currentMediaBanner}
      className={clsx(
        {
          [styles['media-banner']]: true,
          [styles['media-banner--curved']]: variant === 'curved',
          [styles['media-banner--flat']]: variant === 'flat',
        },
        className
      )}
      {...props}
    >
      {image && (!video || videoError) && (
        <div
          className={styles['media-banner__media']}
          data-testid='media-banner-image'
        >
          {image}
        </div>
      )}

      {video && !videoError && (
        <div
          className={clsx(styles['media-banner__media'], {
            [styles['media-banner__media--video']]: true,
          })}
          data-testid='media-banner-video'
        >
          {cloneElement(video as React.ReactElement<any>, {
            onEnded: handleVideoEnd,
            onLoadStart: handleVideoLoad,
            onError: handleVideoError,
          })}
        </div>
      )}

      <div className={styles['media-banner__container']}>
        <div
          className={clsx(styles['media-banner__inner'], {
            [styles['media-banner__inner--full']]: contentWidth === 'full',
          })}
        >
          <div
            className={clsx(styles['media-banner__content'], {
              [styles['media-banner__content--hide']]:
                !videoComplete && videoOptions?.showChildrenOnComplete,
              [styles['media-banner__content--fade-in']]:
                fade_children && videoComplete,
            })}
          >
            {children}
          </div>
        </div>
      </div>

      {icon && (
        <div className={styles['media-banner__icon']}>
          <button
            onClick={scrollTo}
            className={styles['media-banner__icon-circle']}
            type='button'
            aria-hidden
            data-testid='media-banner-icon-button'
          >
            {icon}
          </button>
        </div>
      )}
    </div>
  );
};
