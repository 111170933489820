'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { FormInput } from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';

import { deviceNameAction } from '@/lib/imei-checker';
import { DeviceEntry } from '@/lib/imei-checker/parseCsv';

import styles from './imei.module.scss';
import {
  DeviceCheckerFormProps,
  Notifications,
  deviceNameSchema,
} from './utils';

const mainCopy = `Enter the model name of your device below.`;

export const DeviceNameForm = (props: DeviceCheckerFormProps) => {
  const [selectedDevice, setSelectedDevice] = useState<null | DeviceEntry>(
    null
  );

  const [actionStatus, actionHandler] = useFormState(deviceNameAction, {
    error: undefined,
    payload: undefined,
  });

  const form = useForm<z.infer<typeof deviceNameSchema>>({
    resolver: zodResolver(deviceNameSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    errors: actionStatus.fieldError,
  });

  useEffect(() => {
    if (actionStatus.payload && !selectedDevice)
      setSelectedDevice(actionStatus.payload[0]);
  }, [actionStatus.payload, selectedDevice]);

  return (
    <Box border={false}>
      <Text size='T400'>{mainCopy}</Text>
      <Box gap='md' border={false}>
        <form
          onSubmit={form.handleSubmit((data) => {
            const payload: {
              deviceName: string;
              url?: string;
            } = {
              deviceName: data.deviceName,
            };

            if (props.url) {
              payload.url = props.url;
            }

            actionHandler(payload);

            setSelectedDevice(null);
          })}
        >
          <FlexBlock gap='md' align='start' padding='xs'>
            <FormInput
              {...form.register('deviceName')}
              id='deviceName'
              label={<Text>{'Device name'}</Text>}
              placeholder='i.e. iPhone 15 Pro'
              error={form.formState.errors.deviceName}
            />
            <div className={styles['submit-button-container']}>
              <FormInput type='submit' name='find' id='find' value='Find' />
            </div>
          </FlexBlock>
        </form>
        <Notifications
          actionStatus={actionStatus}
          form={form as any}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      </Box>
    </Box>
  );
};
