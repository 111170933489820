'use client';

import clsx from 'clsx';

import { VideoHTMLAttributes, useEffect, useRef } from 'react';

import styles from './video.module.scss';

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  noFullscreen?: boolean;
  noDownload?: boolean;
  dynamicSrcLoad?: boolean;
  preLoad?: boolean;
  createdAt: string;
  thumbnailUrl: string;
  description?: string;
  title: string;
  src: string;
  posterUrl?: string;
  type?: string;
}

export const Video = ({
  preLoad,
  noFullscreen = false,
  noDownload = false,
  disableRemotePlayback = false,
  dynamicSrcLoad = false,
  className = '',
  createdAt,
  children,
  title,
  description = '',
  thumbnailUrl,
  src,
  type = 'video/mp4',
  ...props
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (dynamicSrcLoad && videoRef.current && src) {
      const videoElement = videoRef.current;

      if (videoElement.currentSrc !== src) {
        videoElement.src = src;
        videoElement.load();
      }
    }
  }, [src, dynamicSrcLoad]);

  return (
    <div itemScope itemType='https://schema.org/VideoObject'>
      <video
        data-testid='video'
        {...props}
        ref={videoRef}
        role='video'
        preload={preLoad ? 'auto' : 'none'}
        controlsList={getEnabledControls({
          noDownload,
          noFullscreen,
          disableRemotePlayback,
        })}
        className={clsx(styles['video'], className)}
      >
        {/* Render sources passed from parent */}
        {children}

        {/* Otherwise render standard src */}
        {!dynamicSrcLoad && <source src={src} type={type} />}
      </video>

      <meta itemProp='name' content={title} />
      <meta itemProp='description' content={description} />
      <meta itemProp='uploadDate' content={createdAt} />
      <meta itemProp='contentUrl' content={src} />
      <meta itemProp='thumbnailUrl' content={thumbnailUrl} />
    </div>
  );
};

const getEnabledControls = (
  controlsObj: Record<string, boolean>
): string | undefined =>
  Object.entries(controlsObj)
    .filter(([_, value]) => Boolean(value))
    .map(([key]) => key.toLowerCase())
    .join(' ') || undefined;
