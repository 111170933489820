'use client';

import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { Video, VideoProps } from '@/components/momentum/components/video';

export interface VideoSource {
  src: string;
  title: string;
  description: string;
  width?: number;
  height?: number;
}

export interface ResponsiveVideoProps
  extends Omit<VideoProps, 'src' | 'title' | 'description'> {
  sm: VideoSource;
  md: VideoSource;
  lg: VideoSource;
  xl: VideoSource;
  alt: string;
  type?: string;
}

export const ResponsiveVideo = ({
  sm,
  md,
  lg,
  xl,
  alt,
  type = 'video/mp4',
  ...props
}: ResponsiveVideoProps) => {
  const isSmBreakpoint = useMedia('(width <= 48em)', false);
  const isMdBreakpoint = useMedia('(width >= 48em) and (width < 64em)', false);
  const isLgBreakpoint = useMedia('(width >= 64em) and (width < 90em)', false);
  const isXlBreakpoint = useMedia('(width >= 90em)', false);
  // XL is (width >= 90em)

  const [video, setUpdatedVideo] = useState<VideoSource | null>(null);

  useEffect(() => {
    const newVideo = (() => {
      if (isSmBreakpoint) return sm;
      if (isMdBreakpoint) return md;
      if (isLgBreakpoint) return lg;
      if (isXlBreakpoint) return xl;
      return null;
    })();

    if (newVideo && newVideo !== video) {
      setUpdatedVideo(newVideo);
    }
  }, [
    isSmBreakpoint,
    isMdBreakpoint,
    isLgBreakpoint,
    isXlBreakpoint,
    video,
    sm,
    md,
    lg,
    xl,
  ]);

  return (
    <Video
      {...props}
      src={video ? video.src : ''}
      description={alt}
      title={xl.title}
      dynamicSrcLoad={true}
    >
      {/* We pass different sources with media so that we can display the correct video source server side. */}
      <source src={xl.src} type={type} media='(width >= 90em)' />
      <source
        src={lg.src}
        type={type}
        media='(width >= 64em) and (width < 90em)'
      />
      <source
        src={md.src}
        type={type}
        media='(width >= 48em) and (width < 64em)'
      />
      <source src={sm.src} type={type} media='(width <= 48em)' />
    </Video>
  );
};
