'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { boolean, z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Button } from '@virginmediao2/momentum-nextjs/button';
import {
  ErrorAlert,
  ErrorAlertsRef,
  FormCheckboxInput,
  FormInput,
  FormRadioGroup,
  FormRadioInput,
  FormTextarea,
} from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormCheckboxGroup } from '@/components/momentum/components/forms/form-checkbox';
import { mobileNumberRegex } from '@/lib/utils/regex-validators';

import styles from './support-request-form.module.css';

const PRIVACY_POLICY_URL =
  'https://www.o2.co.uk/termsandconditions/privacy-policy';
const ACCESS_FOR_YOU_EMAIL = 'accessforyou@o2.com';
const SUBMISSION_SUCCESS_URL =
  'https://www.o2.co.uk/access-for-all/access-for-you-success';

enum AccountHolder {
  YES = 'yes',
  NO = 'no',
}

enum RequestFor {
  ME = 'me',
  ANOTHER = 'another',
}

enum ContactOptions {
  PHONE = 'phone',
  TEXT = 'text',
  EMAIL = 'email',
  LETTER = 'letter',
  OTHER = 'other',
}

enum Nominate {
  YES = 'yes',
  NO = 'no',
}

const supportRequestFormSchema = z
  .object({
    accountHolder: z
      .nativeEnum(AccountHolder)
      .refine(Boolean, 'Requires atleast one option to be selected'),
    firstName: z.string().min(2, 'First name is required'),
    lastName: z.string().min(2, 'Last name is required'),
    mobileNumber: z.string().regex(mobileNumberRegex, 'Invalid mobile number'),
    email: z
      .string()
      .optional()
      .refine((val) => !val || z.string().email().safeParse(val).success, {
        message: 'Invalid email address',
      }),
    requestFor: z
      .nativeEnum(RequestFor)
      .refine(Boolean, 'Requires atleast one option to be selected'),
    contactPreference: z
      .union([z.nativeEnum(ContactOptions), z.literal(false)])
      .array()
      .refine(
        (values) => values.find(Boolean),
        'Checkbox requires at least one option must be selected'
      )
      .transform((val) => val.filter(Boolean)),
    contactPreferenceOtherDetail: z.string().optional(),
    requestForAnotherNumber: z.string().optional(),
    nominate: z
      .nativeEnum(Nominate)
      .refine(Boolean, 'Requires atleast one option to be selected'),
    support: z.string().optional(),
    consent: boolean().refine(Boolean, 'Consent is required'),
  })
  .superRefine((data, ctx) => {
    if (data.accountHolder === AccountHolder.NO) {
      ctx.addIssue({
        code: 'custom',
        path: ['accountHolder'],
        message: `
          Only the O2 account holder can fill out our Support Request Form. 
          If you're a third party supporting an O2 account holder, please contact
          our Access for You Team - accessforyou@o2.com with account holder details.
        `,
      });
    }

    if (
      data.requestFor === RequestFor.ANOTHER &&
      !data.requestForAnotherNumber?.match(mobileNumberRegex)
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['requestForAnotherNumber'],
        message: 'Invalid another number',
      });
    }

    if (
      data.contactPreference.includes(ContactOptions.OTHER) &&
      !data.contactPreferenceOtherDetail?.trim()
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['contactPreferenceOtherDetail'],
        message:
          'Please specify your contact preference if "Other" is selected',
      });
    }
  });

type SupportRequestFormData = z.infer<typeof supportRequestFormSchema>;

const contactPreferenceInputList: Array<{
  name: ContactOptions;
  label: string;
}> = [
  {
    name: ContactOptions.PHONE,
    label: 'Phone',
  },
  {
    name: ContactOptions.TEXT,
    label: 'Text',
  },
  {
    name: ContactOptions.EMAIL,
    label: 'Email',
  },
  {
    name: ContactOptions.LETTER,
    label: 'Letter',
  },
  {
    name: ContactOptions.OTHER,
    label: 'Other, please state below',
  },
];

export const SupportRequestForm = () => {
  const errorAlertsRef = useRef<ErrorAlertsRef>(null);
  const [accountHolder, setAccountHolder] = useState<AccountHolder>(
    AccountHolder.YES
  );
  const [requestFor, setRequestFor] = useState<RequestFor>(RequestFor.ME);
  const [nominate, setNominate] = useState<Nominate>(Nominate.NO);
  const router = useRouter();
  const contactPreferenceRef = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setFocus,
    watch,
  } = useForm<SupportRequestFormData>({
    resolver: zodResolver(supportRequestFormSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (data: SupportRequestFormData) => {
    // TODO:
    // 1. Send email to customer for confirmation
    // 2. Send form data in zip file to accessforyou@o2.com

    router.push(SUBMISSION_SUCCESS_URL);
  };

  const contactPreference = watch('contactPreference');

  const handleSetFocus = (name: string) => {
    if (name === 'contactPreference') {
      setFocus('contactPreference.0');
    } else {
      setFocus(name as any);
    }
  };

  return (
    <Box
      data-testid='support-request-form'
      border={false}
      className={styles['support-request-form']}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isValid && isSubmitted && errors && (
          <Box margin='lg' marginAxis='y' border={false}>
            <ErrorAlert
              ref={errorAlertsRef}
              errors={errors}
              setFocus={handleSetFocus}
            />
          </Box>
        )}

        <Box gap='lg' border={false}>
          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              About you
            </Text>
            <Text>Please complete all fields unless stated otherwise.</Text>
            <Text>Are you the account holder?</Text>
            <FormRadioGroup
              id='accountHolder'
              error={errors?.accountHolder?.message}
            >
              <Box gap='lg' border={false} padding='sm'>
                <FormRadioInput
                  isFlag
                  label={"Yes, I'm the account holder"}
                  value='yes'
                  {...register('accountHolder')}
                  onChange={() => setAccountHolder(AccountHolder.YES)}
                  checked={accountHolder === AccountHolder.YES}
                />
                <FormRadioInput
                  isFlag
                  label="No, I'm not the account holder"
                  value='no'
                  {...register('accountHolder')}
                  onChange={() => setAccountHolder(AccountHolder.NO)}
                  checked={accountHolder === AccountHolder.NO}
                />
              </Box>
            </FormRadioGroup>
            <Box border={false} gap='lg'>
              <FormInput
                id='firstName'
                label={<Text>First name</Text>}
                {...register('firstName')}
                error={errors?.firstName}
                placeholder='e.g. John'
              />
              <FormInput
                id='lastName'
                label={<Text>Last name</Text>}
                {...register('lastName')}
                error={errors?.lastName}
                placeholder='e.g. Smith'
              />
              <FormInput
                id='mobileNumber'
                label={<Text>O2 mobile number</Text>}
                {...register('mobileNumber')}
                error={errors?.mobileNumber}
                placeholder='e.g. 07543654437'
              />
              <FormInput
                id='email'
                label={<Text>Email address (optional)</Text>}
                {...register('email')}
                error={errors?.email}
                placeholder='e.g. j.smith@email.com'
              />
            </Box>
            <Text>Who is this request for?</Text>
            <FormRadioGroup id='requestFor' error={errors?.requestFor?.message}>
              <Box gap='lg' border={false} padding='sm'>
                <FormRadioInput
                  isFlag
                  label={"It's for me"}
                  value='me'
                  {...register('requestFor')}
                  onChange={() => setRequestFor(RequestFor.ME)}
                  checked={requestFor === RequestFor.ME}
                />
                <FormRadioInput
                  isFlag
                  label='Another number on my account'
                  value='another'
                  {...register('requestFor')}
                  onChange={() => setRequestFor(RequestFor.ANOTHER)}
                  checked={requestFor === RequestFor.ANOTHER}
                />
                {requestFor === RequestFor.ANOTHER && (
                  <Box border={false}>
                    <FormInput
                      id='requestForAnotherNumber'
                      label='Another number (if different from mobile number)'
                      {...register('requestForAnotherNumber')}
                      error={errors?.requestForAnotherNumber}
                      placeholder='e.g. 07543654437'
                    />
                  </Box>
                )}
              </Box>
            </FormRadioGroup>
          </Box>

          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              Contact preference
            </Text>
            <Text>
              We&apos;ll try to get in touch using your preferred contact
              method, but sometimes you might get an automatically generated
              text or email. Please select which method of contact you prefer.
              We may need to contact you about the information you share on this
              form. This could come from an unknown number or email address.
            </Text>
            <FormCheckboxGroup
              id='contactPreference'
              error={errors?.contactPreference?.root?.message}
              ref={contactPreferenceRef}
            >
              <Box gap='lg' border={false} padding='sm'>
                {contactPreferenceInputList.map((item, index) => {
                  return (
                    <FormCheckboxInput
                      label={item.label}
                      key={item.name}
                      value={item.name}
                      isSquare
                      isFlag
                      {...register(`contactPreference.${index}`)}
                      error={!!errors?.contactPreference}
                    />
                  );
                })}
              </Box>
              {contactPreference?.includes(ContactOptions.OTHER) && (
                <Box border={false}>
                  <FormInput
                    id='contactPreferenceOtherDetail'
                    label={<Text>Other contact preference</Text>}
                    placeholder='Type here'
                    {...register('contactPreferenceOtherDetail')}
                    error={errors?.contactPreferenceOtherDetail}
                  />
                </Box>
              )}
            </FormCheckboxGroup>
          </Box>

          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              Your account
            </Text>
            <Text>
              Would you like to nominate a trusted friend or family member to
              help you manage your account?
            </Text>
            <FormRadioGroup id='nominate' error={errors?.nominate?.message}>
              <Box gap='lg' border={false} padding='sm'>
                <FormRadioInput
                  isFlag
                  label={'Yes, I would like to nominate'}
                  value='yes'
                  {...register('nominate')}
                  onChange={() => setNominate(Nominate.YES)}
                  checked={nominate === Nominate.YES}
                />
                <FormRadioInput
                  isFlag
                  label="No, I don't want to nominate"
                  value='no'
                  {...register('nominate')}
                  onChange={() => setNominate(Nominate.NO)}
                  checked={nominate === Nominate.NO}
                />
              </Box>
            </FormRadioGroup>
          </Box>

          <Box gap='lg' border={false}>
            <Text size='T500' weight='bold' as='h2'>
              Support needs
            </Text>
            <Text>
              Please tell us about your disability or personal circumstances,
              and the adjustments you need us to make for you. This information
              will be recorded on your account for future reference. The
              information you provide will help us assess your requirements and
              any reasonable adjustments to how we work and communicate with
              you.
            </Text>
            <Box border={false}>
              <FormTextarea
                id='support'
                {...register('support')}
                placeholder='Type here'
              />
            </Box>
          </Box>

          <Box gap='lg' border={false}>
            <Box border={false} padding='sm'>
              <FormCheckboxInput
                label='I consent to you, Telefonica UK Limited, using my information
                to assess my needs and adjust the services you provide to me.'
                isSquare
                isFlag
                {...register('consent')}
                error={!!errors?.consent}
              />
            </Box>
            <Text>
              You can withdraw your consent or update your details at any time
              by emailing{' '}
              <Link href={`mailto:${ACCESS_FOR_YOU_EMAIL}`} target='_blank'>
                {ACCESS_FOR_YOU_EMAIL}
              </Link>
              . Find out more about how we use your data in our{' '}
              <Link href={PRIVACY_POLICY_URL} target='_blank'>
                Privacy Policy
              </Link>
              .
            </Text>
          </Box>

          <Button type='submit'>Submit form</Button>
        </Box>
      </form>
    </Box>
  );
};
